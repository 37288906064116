import { navigateTo } from '@/utils/navigateTo';
import bemBlock from '@gik/core/utils/bemBlock';
import { PopoverListItem } from '@gik/ui/PopoverListItem';
import React from 'react';
import { Layout } from '../gik/Layout/Layout';

const blockName = 'menu-item';

interface MenuItemBase {
  label: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  category?: string;
  hideOnSearch?: boolean;
  icon?: SvgrComponent;
  className?: string;
}

export interface MenuItemHref extends MenuItemBase {
  href: string;
}

export interface MenuItemClickable extends MenuItemBase {
  onClick?: () => void;
}

export type IMenuItem = MenuItemClickable | MenuItemHref;

export interface IMenuItemProps {
  item: IMenuItem;
  className?: string;
}

export function MenuItem({ item, className }: IMenuItemProps) {
  const bem = bemBlock(blockName);
  const { label, icon } = item as MenuItemBase;
  const { href } = item as MenuItemHref;
  const { onClick } = item as MenuItemClickable;

  function handleClick() {
    if (onClick) {
      onClick();
    } else if (href) {
      navigateTo(href);
    }
  }

  const props = {
    className: bem(null, null, className),
    prepend: (
      <Layout className={bem('item-wrapper')} direction={'column'} align={'center'} perpendicular={'start'} gap={1}>
        <div className={bem('label')}>{item.label}</div>
        {item.subtitle && <div className={bem('subtitle')}>{item.subtitle}</div>}
      </Layout>
    ),
    icon: icon,
  };

  return <PopoverListItem {...props} onClick={handleClick} href={href} />;
}

export interface IMenuCategoryTitleProps {
  item: MenuItemBase;
  className?: string;
}

export function MenuCategoryTitle({ item, className }: IMenuCategoryTitleProps) {
  const bem = bemBlock('menu-category-title');

  const props = {
    className: bem(null, null, className),
    prepend: <div className={bem('label')}>{item.label}</div>,
    icon: item.icon,
  };

  return <PopoverListItem {...props} />;
}
