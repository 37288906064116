import { useInkind } from '@gik/api/inkinds/inkind';
import { SignInFlowStartContentFormInputNames } from '@gik/auth/enums/SignInFlowStartContentFormInputNames';
import { signin } from '@gik/auth/utils/auth';
import { useInkindCan } from '@gik/core/store/permissions';
import { useUserStore } from '@gik/core/store/UserStore';
import { nativeShareSupported } from '@gik/core/utils/browser';
import { openShareInkindModal } from '@gik/inkind-page/components/ShareInkindModal/openShareInkindModal';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { useRouter } from 'next/router';
import React from 'react';
import useSearchParam from 'react-use/lib/useSearchParam';

export function useQueryStringHandler() {
  const router = useRouter();

  const showThankYouModal = useSearchParam('sendThankYou');
  const sendEmailInvitations = useSearchParam('sendEmailInvitations');
  const sharePage = useSearchParam('sharePage');
  const inkindRouteIdParam = useSearchParam('inkindRouteId');
  const inkindRouteIdQuery = router?.query?.id as string;
  const inkindRouteIdFromStore = useInkindStore(state => state.inkindRouteId);
  const inkindRouteId = inkindRouteIdParam || inkindRouteIdQuery || inkindRouteIdFromStore;
  const userId = useUserStore(state => state.id);
  const invitationEmailAddress = useSearchParam('invitationEmailAddress');
  const [openedShareModal, setOpenedShareModal] = React.useState(false);

  const { data: inkind } = useInkind(inkindRouteId, null, router.asPath.startsWith('/wallets/') ? true : undefined);

  const inkindId = inkind?.id;
  const canEdit = useInkindCan('manage', inkindRouteId, inkind?.groupId);

  /**
   * Open send thank you modal if the correct querystring paramaters were passed
   */
  React.useEffect(() => {
    if (!userId && invitationEmailAddress)
      signin({ initialValues: { [SignInFlowStartContentFormInputNames.EmailAddress]: invitationEmailAddress } });
    if (!userId && (sendEmailInvitations || sharePage)) signin();
    if (inkind && canEdit && (sendEmailInvitations || sharePage) && !openedShareModal) {
      setOpenedShareModal(true);
      openShareInkindModal(
        {
          isOrganizer: canEdit,
          pageTitle: inkind.title,
          pageShortUrl: inkind.shortUrl,
          inkindRouteId: inkind.routeId,
          emailInvitationsButton: canEdit,
          moreOptionsButton: nativeShareSupported(),
          pageNumber: sendEmailInvitations ? 1 : 0,
        },
        'inkindPage',
        inkind.url,
        canEdit
      );
    }
  }, [
    inkindId,
    inkind,
    userId,
    showThankYouModal,
    sharePage,
    invitationEmailAddress,
    canEdit,
    sendEmailInvitations,
    openedShareModal,
  ]);
}
