import type { IPageLayoutAttachment } from '@gik/ui/gik/AppLayout/AppLayout';
import { AppLayout } from '@gik/ui/gik/AppLayout/AppLayout';
import React from 'react';

export type PageLayoutProps = {
  className?: string;
  key?: string;
} & IPageLayoutAttachment;

export function PageLayout({
  navBarAttachment,
  footerAttachment,
  children,
  className,
}: React.PropsWithChildren<PageLayoutProps>): React.ReactElement {
  return (
    <AppLayout className={className} navBarAttachment={navBarAttachment} footerAttachment={footerAttachment}>
      {children}
    </AppLayout>
  );
}
