import ChevronDown from '@heroicons/react/solid/ChevronDownIcon';
import routes from '@gik/core/routes';
import type { MenuListItem } from '../../Menu';
import { openSearch } from '@gik/search/components/SearchStore';

export const menuBar: MenuListItem[] = [
  {
    title: 'How It Works',
    appendIcon: ChevronDown,
    menu: [
      { label: 'How to start a Page', subtitle: 'Step-by-step help to get started', href: routes.howItWork },
      { label: 'Page Features', subtitle: 'Learn how Give InKind works for you', href: routes.features },
      { label: 'Tips & Sample Pages', subtitle: 'Ideas to optimize your InKind Page', href: routes.tipsAndSamplePages },
      { label: 'Common Questions', subtitle: 'FAQs', href: routes.commonQuestions },
    ],
  },
  {
    title: 'Organize',
    appendIcon: ChevronDown,
    menu: [
      { label: 'Meal Trains & More', subtitle: 'Organize meals or in-person support', href: routes.mealTrain },
      { label: 'Flexible Gift Cards', subtitle: 'Recipients choose how to use', href: routes.organizeGiftCard },
      { label: 'Fundraise Your Way', subtitle: 'Connect all your favorites, no fees', href: routes.fundraising },
      { label: 'Share Updates', subtitle: 'Keep followers informed', href: routes.updates },
      { label: 'For Groups', subtitle: 'Features for Churches, HR, and more', href: routes.forGroups },
      { label: 'For Non-Profits', subtitle: 'Give donors more ways to help', href: routes.forNonProfits },
    ],
  },
  {
    title: 'Support',
    appendIcon: ChevronDown,
    menu: [
      { label: 'Gift Cards', subtitle: 'Browse our 300+ options', href: routes.giftCard },
      { label: 'Situations', subtitle: 'Find support ideas for any situation', href: routes.give },
      { label: 'Support Guides', subtitle: 'Explore thoughtful ways to help', href: routes.blog },
      { label: 'Find a Page', subtitle: 'Looking for someone? Start here', onClick: () => openSearch('MenuBar') },
    ],
  },
];
