import { breakpointBem } from '@gik/core/hooks/hooks/BreakpointHooks';
import { useIsMounted } from '@gik/core/hooks/hooks/useIsMounted';
// import { useIsMounted } from '@gik/core/hooks/hooks/useIsMounted';
import routes from '@gik/core/routes';
import { useUserStore } from '@gik/core/store/UserStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { CreateClickedLocation } from '@gik/create/enums/CreateAnalyticsEnums';
import { useCreateAnalyticsController } from '@gik/create/hooks/useCreateAnalyticsController';
import { translationKeys } from '@gik/i18n/en/app';
import { SearchButton } from '@gik/search/components';
import { Button } from '@gik/ui/Button';
import type { IAppHeaderProps } from '@gik/ui/gik/AppHeader/AppHeader';
import { AppHeaderSignInButton } from '@gik/ui/gik/AppHeader/AppHeaderSignInButton';
import { menuBar } from '@gik/ui/gik/AppHeader/menuItems';
import { UserAvatarMenu } from '@gik/ui/gik/UserAvatarMenu';
import { MenuBar } from '@gik/ui/Menu';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function AppHeaderNav({ breakpoint }: IAppHeaderProps) {
  const bem = useBemCN('app-header-nav');
  const { t } = useTranslation();
  const { trackCreateClicked } = useCreateAnalyticsController();
  const userId = useUserStore(state => state.id);
  const isImpersonating = useUserStore(state => state.isImpersonating);
  const isMounted = useIsMounted();

  return (
    <ul {...bem(null, breakpointBem(breakpoint))}>
      <li {...bem('nav-search')}>
        <SearchButton />
      </li>

      <MenuBar splitColumns={2} menuList={menuBar} />

      <li onClick={() => trackCreateClicked(CreateClickedLocation.PAGE_HEADER)}>
        <Button type={'a'} href={routes.create} variant="secondary" pill {...bem('btn-start')}>
          {t(translationKeys.startAPage).toString()}
        </Button>
      </li>

      {userId && isMounted() && (
        <li {...bem('user-avatar-container')}>
          <UserAvatarMenu isImpersonating={isImpersonating} />
        </li>
      )}
      {!userId && isMounted() && <AppHeaderSignInButton breakpoint={breakpoint} />}
    </ul>
  );
}
